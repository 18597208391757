import request from "@/utils/axios";
import type { BaseParams, ListResponse } from "@/types";
import type {
  InspectorAct,
  ActStatus,
  InspectorActParams,
  InspectorActDetails,
  InspectortActReason,
  Status,
} from "../types";
import { StatusCount } from "@/features/acts-list/types";
import requestWithoutData from "@/utils/axiosWithoutData";

export async function getActStatus(
  params?: BaseParams,
): Promise<ListResponse<ActStatus[]>> {
  const res: ListResponse<ActStatus[]> = await request({
    url: "/inspector-act-status/",
    method: "get",
    params,
  });

  return res;
}

export async function getInspectorActs(
  params?: InspectorActParams,
): Promise<ListResponse<InspectorAct[]>> {
  const res: ListResponse<InspectorAct[]> = await request({
    url: "/inspector-act/",
    method: "get",
    params,
  });

  return res;
}

export async function getStatusCountList(
  params: BaseParams = {},
): Promise<StatusCount[]> {
  const result: StatusCount[] = await request({
    url: "/ek-act-status-count/",
    method: "get",
    params,
  });

  return result;
}

export async function getInspectorActDetails(
  id: number,
  params?: BaseParams,
): Promise<InspectorActDetails> {
  const res: InspectorActDetails = await request({
    url: `/inspector-act/${id}/`,
    method: "get",
    params,
  });

  return res;
}

export async function getInspectorActReasons(
  params?: BaseParams,
): Promise<InspectortActReason[]> {
  const res: InspectortActReason[] = await request({
    url: "/inspector-act-reasons-list/",
    method: "get",
    params,
  });

  return res;
}

export async function getPdfDownload(id: number): Promise<void> {
  await requestWithoutData({
    url: `/inspector-act-pdf/${id}/`,
    method: "get",
  });
}

export async function updateInspectorActStatus(
  id: number,
  data: { status_id: number; reason?: number; description?: string },
): Promise<void> {
  await request({
    url: `/inspector-act-update/${id}/`,
    method: "put",
    data,
  });
}

export async function getInspectorStatuses(): Promise<ListResponse<Status[]>> {
  const res: ListResponse<Status[]> = await request({
    url: `/inspector-act-status/`,
    method: "get",
  });

  return res;
}

export async function updateStatusAccept(violationId: number): Promise<void> {
  await request({
    url: `/inspector-act/update-status-accept/${violationId}/`,
    method: "put",
  });
}

export async function updateStatusCancel(
  violationId: number,
  data?: { reason?: number; description?: string },
): Promise<{ reason?: string; description?: string }> {
  const res: { reason?: string; description?: string } = await request({
    url: `/inspector-act/update-status-cancel/${violationId}/`,
    method: "put",
    data,
  });

  return res;
}

export async function updateStatusReject(violationId: number): Promise<void> {
  await request({
    url: `/inspector-act/update-status-reject/${violationId}/`,
    method: "put",
  });
}
