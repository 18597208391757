import { useLangContext } from "@/contexts";
import { useQuery } from "@tanstack/react-query";
import { Flex, Spin } from "antd";
import type L from "leaflet";
import { useEffect, useMemo, useRef } from "react";
import { MapContainer } from "react-leaflet";
import { useLocation, useSearchParams } from "react-router-dom";
import { getMapsDistrictPolygons, getMapsRegionPolygons } from "../api";
import MainMapIndex from "./MainMapIndex";
import queryString from "query-string";

const fallbackGeometry =
  '{"type": "MultiPolygon", "coordinates": [[[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]]]}';

const parseJSON = (str, fallback) => {
  try {
    return JSON.parse(str?.replaceAll("'", '"') ?? fallback);
  } catch {
    return JSON.parse(fallback);
  }
};

const mapData = (data, lang) =>
  data?.results
    ?.filter((val) => val.geometry)
    .map((item) => ({
      type: "Feature",
      id: item.region_id,
      properties: {
        region_id: item.region_id,
        district_id: item.district_id,
        mahalla_id: item.mtp_id,
        density: 100,
      },
      geometry: parseJSON(item.geometry, fallbackGeometry),
    }));

export default function DashboardMapMain({
  mapDataQueryRegions,
  mapDataQueryDistricts,
}: any): JSX.Element {
  const { lang } = useLangContext();
  const { search } = useLocation();
  const initialMarkersRef = useRef<L.Marker[]>([]);
  const markersRef = useRef<L.Marker[]>([]);
  const [searchParams, setSearchParams] = useSearchParams("");
  const currentRegionId = searchParams.get("region_id");
  const currentFromTokenRegionId = JSON.parse(
    localStorage.getItem("user") || "{}",
  ).region;

  const { data: regionData, isFetching: laoderRegionPolygons } = useQuery({
    queryKey: ["maps-region-polygons"],
    queryFn: getMapsRegionPolygons,
  });

  const { data: districtData, isFetching: loaderDistrictPolygons } = useQuery({
    queryKey: ["maps-district-polygons", currentRegionId],
    queryFn: async () =>
      await getMapsDistrictPolygons({
        district__region_id: Number(currentRegionId),
      }),
    enabled: !!currentRegionId,
  });

  useEffect(() => {
    initialMarkersRef.current = markersRef.current;
  }, []);

  const outputRegionArray = useMemo(
    () => mapData(regionData, lang),
    [regionData, lang, currentRegionId, laoderRegionPolygons, queryString],
  );

  const outputDistrictArray = useMemo(
    () => mapData(districtData, lang),
    [districtData, lang, currentRegionId, loaderDistrictPolygons],
  );

  return (
    <Flex className="w-full h-full relative rounded-[12px] overflow-hidden">
      {(loaderDistrictPolygons || laoderRegionPolygons) && (
        <Flex className="w-full h-full items-center justify-center absolute top-0 right-0 z-[999999] bg-[#d8f3dc82]">
          <Spin
            spinning
            className="w-full h-full flex items-center justify-center"
          />
        </Flex>
      )}
      <Flex className="w-full h-full">
        <MapContainer
          center={[41.53, 64.51]}
          zoom={5.6}
          zoomDelta={0.25}
          zoomSnap={0}
          style={{ width: "100%", height: "100%", borderRadius: "12px" }}
          attributionControl={false}
        >
          <MainMapIndex
            mapDataQueryRegions={mapDataQueryRegions}
            mapDataQueryDistricts={mapDataQueryDistricts}
            outputRegionArray={outputRegionArray}
            outputDistrictArray={outputDistrictArray}
            markersRef={markersRef}
            search={search}
            currentFromTokenRegionId={currentFromTokenRegionId}
          />
        </MapContainer>
      </Flex>
    </Flex>
  );
}
