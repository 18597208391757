import { Layout, message, theme } from "antd";
import { useTranslation } from "react-i18next";
import type { InspectorsState } from "../../types";

const { Header, Content } = Layout;

export default function useInspectorsState(): InspectorsState {
  const { t } = useTranslation();

  const [messageApi, contextHolder] = message.useMessage();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return {
    Header,
    Content,
    colorBgContainer,
    contextHolder,
    t,
  };
}
