/* eslint-disable no-param-reassign */
import axios from "axios";
import type { AxiosError } from "axios";
import settings from "@/config/settings";
import { refreshToken } from "@/features/auth/api";
import type { AppLang } from "@/types";

const baseUrl = (lang: string | null): string => {
  if (lang === "uzLatin") {
    return `${settings.baseURL}/uz/api/v1`;
  }
  if (lang === "uzCryllic") {
    return `${settings.baseURL}/ur/api/v1`;
  }
  return `${settings.baseURL}/ru/api/v1`;
};

const acceptLangMap: Record<AppLang, string> = {
  uzLatin: "uz",
  uzCryllic: "ur",
  ru: "ru",
};

const request = axios.create({
  // baseURL: settings.baseURL,
  timeout: settings.requestTimeout,
});

request.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  const lang = localStorage.getItem("i18nextLng") as AppLang | null;

  if (token !== null) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.baseURL = baseUrl(lang);
  // config.validateStatus = (status) => status < 500;

  config.headers["Accept-Language"] = acceptLangMap[lang ?? "ru"];

  return config;
}, errorHandler);

request.interceptors.response.use((response) => response.data, errorHandler);

export async function errorHandler(error: AxiosError): Promise<void> {
  if (error.response !== null) {
    // server responded with a status code that falls out of the range of 2xx
    if (error.response?.status === 401) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const refresh_token = localStorage.getItem("refresh_token");

      if (refresh_token !== null) {
        try {
          const res = await refreshToken({ refresh: refresh_token });
          const { refresh, access } = res.data;
          localStorage.setItem("refresh_token", refresh);
          localStorage.setItem("access_token", access);
          window.location.reload();
        } catch (err) {
          localStorage.setItem("refresh_token_error", JSON.stringify(err));
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("access_token");
          localStorage.removeItem("user");
          window.location.replace("/auth?session_expired=1");
        }
      } else {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
        window.location.replace("/auth?session_expired=1");
      }
    }

    await Promise.reject(error.response);
  }

  if (error.request !== null) {
    // no response received from server
    await Promise.reject(error.request);
  }

  // something happened in setting up the request
  console.error(error.message);

  console.log("Error config object:", error.config);

  // Using toJSON you get an object with more information about the HTTP error
  console.log("\nError object as json:", error.toJSON());

  await Promise.reject(error);
}

export default request;
