import MessageContent from "@/components/message-content";
import { message } from "antd";
import queryString from "query-string";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GeoJSON, Pane, TileLayer, useMap } from "react-leaflet";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function MainMapIndex({
  outputRegionArray,
  outputDistrictArray,
  mapDataQueryRegions,
  mapDataQueryDistricts,
  markersRef,
  search,
  currentFromTokenRegionId,
}: any): JSX.Element {
  const navigate = useNavigate();
  const mapRef = useMap();
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();

  const createTextMarker = (text, latlng, regionId) => {
    const icon = L.divIcon({
      className: "custom-text-marker",
      html: `<div style="background-color: white; padding: '3px 5px'; border-radius: 4px; box-shadow: 0px 0px 10px 0px #4480FF99; display: flex; align-items: center; justify-content: center;">${text}</div>`,
      iconSize: [25, 25],
      iconAnchor: [12.5, 12.5],
    });

    const marker = L.marker(latlng, { icon, pane: "markersPane" });

    // Add click event to navigate
    marker.on("click", () => {
      if (regionId !== currentFromTokenRegionId && currentFromTokenRegionId !== 15) {
        return;
      }
      if (regionId) {
        navigate(`/dashboard?region_id=${regionId}`);
      }
    });

    return marker;
  };

  const colorsGenerator = (val: any): any => {
    if (val >= 0 && val <= 500) {
      return {
        color: "#06D188",
        bg: "#06D18840",
      };
    }
    if (val >= 500 && val <= 1000) {
      return {
        color: "#FFD100",
        bg: "#FFD10040",
      };
    }
    return {
      color: "#F64F44",
      bg: "#F64F4440",
    };
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: t('You do not have permission to view this region'),
    });
  };

  const onEachRegionFeature = useCallback(
    (feature, layer) => {
      const originalStyle = {
        fillColor: colorsGenerator(
          mapDataQueryRegions?.region_counts?.find(
            (val) => val.region_id === feature.properties.region_id,
          )?.count ?? 0,
        ).bg,
        weight: 3,
        opacity: 1,
        color: colorsGenerator(
          mapDataQueryRegions?.region_counts?.find(
            (val) => val.region_id === feature.properties.region_id,
          )?.count ?? 0,
        ).color,
        fillOpacity: 0.5,
        zIndex: 50,
      };

      layer.setStyle(originalStyle);

      layer.on({
        mouseover: () => {
          layer.setStyle({
            fillColor: "#3266D5",
            fillOpacity: 0.7, // You can adjust the opacity if needed
          });
        },
        mouseout: () => {
          // Reset to the original style
          layer.setStyle(originalStyle);
        },
        click: () => {
          if (feature.properties.region_id !== currentFromTokenRegionId && currentFromTokenRegionId !== 15) {
            error();
            return;
          }
          if (feature.properties.region_id) {
            navigate(`/dashboard?region_id=${feature.properties.region_id}`);
          }
        },
      });

      // const text = mapDataQueryRegions?.region_counts?.find(
      //   (val) => val.region_id === feature.properties.region_id,
      // )?.count;

      // if (text) {
      //   // Optional: Add your marker logic here if needed
      // } else {
      //   console.log("no text");
      // }
    },
    [mapDataQueryRegions?.region_counts, navigate],
  );

  const onEachDistrictFeature = useCallback(
    (feature, layer) => {
      console.log("feature", feature.properties.district_id);

      const originalStyle = {
        fillColor: colorsGenerator(
          mapDataQueryDistricts?.districts?.find(
            (val) => val.district_id === feature.properties.district_id,
          )?.count ?? 0,
        ).bg,
        weight: 3,
        opacity: 1,
        color: "#59C3FF", // District border color
        fillOpacity: 0.5,
        zIndex: 50,
      };

      layer.setStyle(originalStyle);

      layer.on({
        mouseover: () => {
          layer.setStyle({
            fillColor: "#3266D5",
            fillOpacity: 0.7,
          });
        },
        mouseout: () => {
          // Reset to the original style
          layer.setStyle(originalStyle);
        },
        click: () => {
          if (feature.properties.district_id) {
            navigate(
              `/dashboard?${queryString.stringify({
                ...queryString.parse(search),
                district_id: feature.properties.district_id,
              })}`,
            );
          }
        },
      });

      const text = mapDataQueryDistricts?.districts?.find(
        (val) => val.district_id === feature.properties.district_id,
      )?.count;

      if (text) {
        const centerCoordinates = layer.getBounds().getCenter();
        const marker = createTextMarker(text, centerCoordinates).addTo(mapRef);
        markersRef.current.push(marker);
        console.log("text", centerCoordinates);
      }
    },
    [mapDataQueryDistricts?.districts, navigate, search, mapRef, markersRef],
  );

  // if in query params changed region_id or district_id flyto to this region or district with bounds and zoom level
  const [searchParams, setSearchParams] = useSearchParams("");
  // region id
  const regionId = searchParams.get("region_id");

  useEffect(() => {
    if (regionId) {
      const region = outputRegionArray?.find(
        (val) => val?.id === Number(regionId),
      );

      if (region) {
        markersRef.current.forEach((marker) => marker.remove());
        markersRef.current = [];

        const regionZoomLevel: Record<number, number> = {
          1: 9,
          2: 7.5,
          3: 8,
          4: 8,
          5: 6.8,
          6: 8.7,
          7: 8,
          8: 8,
          9: 9,
          10: 10.5,
          11: 7.8,
          12: 9,
          13: 8.2,
          14: 6.8,
        };

        const bounds = L.geoJSON(region?.geometry).getBounds().getCenter();

        const zoomLevel = regionZoomLevel[region?.id];

        if (zoomLevel && mapRef) {
          const newCenter: [number, number] = [bounds.lat, bounds.lng];
          mapRef.flyTo(newCenter, zoomLevel);
        }
      }
    } else {
      mapRef.setView([41.53, 64.51], 5.6);

      markersRef.current.forEach((marker) => marker.remove());

      markersRef.current = [];

      outputRegionArray?.forEach((region) => {
        const text = mapDataQueryRegions?.region_counts?.find(
          (val) => val.region_id === region.properties.region_id,
        )?.count;

        if (text) {
          const centerCoordinates = L.geoJSON(region.geometry)
            .getBounds()
            .getCenter();

          const marker = createTextMarker(
            text,
            centerCoordinates,
            region.properties.region_id,
          ).addTo(mapRef);
          markersRef.current.push(marker);
        }
      });
    }
  }, [
    mapDataQueryRegions?.region_counts,
    searchParams,
    outputRegionArray,
    mapRef,
    markersRef,
    regionId,
  ]);

  return (
    <>
      {contextHolder}
      <Pane style={{ zIndex: 500 }} name="">
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      </Pane>

      <Pane style={{ zIndex: 999 }} name="geoJsonPane">
        {outputRegionArray?.length && (
          <GeoJSON
            data={{
              type: "FeatureCollection",
              features: outputRegionArray,
            }}
            onEachFeature={onEachRegionFeature}
          />
        )}
      </Pane>
      <Pane style={{ zIndex: 1000 }} name="geoJsonPane1">
        {outputDistrictArray?.length && (
          <GeoJSON
            data={{
              type: "FeatureCollection",
              features: outputDistrictArray,
            }}
            onEachFeature={onEachDistrictFeature}
          />
        )}
      </Pane>
      <Pane style={{ zIndex: 3000 }} name="markersPane"></Pane>
    </>
  );
}
