import { useQuery } from "@tanstack/react-query";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getChartData, getMapDataDistricts, getMapDataRegion } from "../../api";
import AllActsByStatus from "../../components/AllActsByStatus";
import AmountOfDamage from "../../components/AmountOfDamage";
import DashboardMapMain from "../../components/DashboardMapMain";
import DistributedSubmittedWorks from "../../components/DistributedSubmittedWorks";
import HeaderDashboard from "../../components/HeaderDashboard";
import RegionBarChart from "../../components/RegionBarChart";
import ReturnedWorks from "../../components/ReturnedWorks";
import SentActQuentity from "../../components/SentActQuentity";
import "../../styles/dashboard.scss";

function DashboardMain(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams("");
  const currentRegionId = searchParams.get("region_id");
  const currentDistrictId = searchParams.get("district_id");

  const [filters, setFilters] = useState({
    region_id: searchParams.get("region_id"),
    district_id: searchParams.get("district_id"),
    violation_type: searchParams.get("violation_type"),
    min_date: searchParams.get("min_date"),
    max_date: searchParams.get("max_date"),
  });

  const { data: mapDataQueryRegions } = useQuery({
    queryKey: ["map-data-region", filters],
    queryFn: async () => await getMapDataRegion(filters),
    placeholderData: {
      region_counts: [],
    },
  });

  const { data: mapDataQueryDistricts } = useQuery({
    queryKey: ["map-data-districts", currentRegionId, filters],
    queryFn: async () => await getMapDataDistricts(filters, currentRegionId),
    placeholderData: {
      district_counts: [],
    },
    enabled: !!currentRegionId,
  });

  const { data: chartDatas } = useQuery({
    queryKey: ["chart-data", filters],
    queryFn: async () => await getChartData(filters),
    placeholderData: {
      region_counts: [],
    },
  });

  useEffect(() => {
    if (currentRegionId) {
      setFilters((prev) => ({
        ...prev,
        region: Number(currentRegionId) as any,
        district: undefined as any,
      }));
    }

    if (currentDistrictId) {
      setFilters((prev) => ({
        ...prev,
        district: Number(currentDistrictId) as any,
      }));
    }
  }, [currentRegionId, currentDistrictId]);

  return (
    <div className="h-[calc(100vh-55px)] bg-white ">
      <HeaderDashboard filters={filters} setFilters={setFilters} />

      <div className="parent px-8 bg-white !rounded-[12px] !overflow-hidden">
        <div className="div1 border-none">
          <DashboardMapMain
            mapDataQueryRegions={mapDataQueryRegions}
            mapDataQueryDistricts={mapDataQueryDistricts}
          />
        </div>

        <div className="div2 bg-[#FAFBFC] rounded-[12px]">
          <RegionBarChart
            mapDataQueryRegions={mapDataQueryRegions}
            mapDataQueryDistricts={mapDataQueryDistricts}
            currentRegionId={currentRegionId}
          />
        </div>

        <div className="div3 rounded-[12px] overflow-hidden p-[16px]">
          <DistributedSubmittedWorks
            data={{
              count_mvd_to_procroture: chartDatas?.count_mvd_to_procroture,
              procrature_to_mvd: chartDatas?.procrature_to_mvd,
            }}
          />
        </div>

        <div className="div4">
          <AmountOfDamage
            data={{
              received_total_sum_emi: chartDatas?.received_total_sum_emi,
              received_total_summ_procroture:
                chartDatas?.received_total_summ_procroture,
              send_total_summ_emi: chartDatas?.send_total_summ_emi,
              send_total_summ_procroture:
                chartDatas?.send_total_summ_procroture,
            }}
          />
        </div>

        <div className="div5">
          <AllActsByStatus
            data={{
              mvd: {
                send: chartDatas?.status_count_mvd?.find(
                  (item) => item.emi_status === 1,
                )?.count,
                in_proccess: chartDatas?.status_count_mvd?.find(
                  (item) => item.emi_status === 2,
                )?.count,
                done: chartDatas?.status_count_mvd?.find(
                  (item) => item.emi_status === 12,
                )?.count,
              },
              prokuratura: {
                send: chartDatas?.status_count_procroture?.find(
                  (item) => item.status_procroture === "send",
                )?.count,
                in_proccess: chartDatas?.status_count_procroture?.find(
                  (item) => item.status_procroture === "in_proccess",
                )?.count,
                done: chartDatas?.status_count_procroture?.find(
                  (item) => item.status_procroture === "finished",
                )?.count,
              },
            }}
          />
        </div>

        <div className="div6">
          <SentActQuentity
            data={chartDatas?.organizations_counts_sends?.count?.map(
              (item) => ({
                name: item.organization_type_name,
                value: item.count,
              }),
            )}
          />
        </div>

        <div className="div7">
          <ReturnedWorks
            data={{
              mvd: chartDatas?.count_reject_mvd?.map((item) => ({
                name: item?.employee_organization_name,
                value: item?.count,
              })),
              prokuratura: chartDatas?.count_reject_procroture?.map((item) => ({
                name: item?.employee_organization_name,
                value: item?.count,
              })),
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default DashboardMain;
