import { MailFilled, PhoneFilled } from "@ant-design/icons";
import RefIcon from "@ant-design/icons/lib/icons/CompassFilled";
import { Button, Flex, Input, Modal, Result, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";

export default function SupportPage(): React.ReactElement {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState<any>({
    name: "",
    phone: "",
    message: "",
    email: "",
  });
  return (
    <Flex className="w-full h-[100vh] items-center justify-center">
      <Flex className="grid grid-cols-2 gap-4 max-w-[1400px] mx-auto">
        <Flex vertical className="gap-[24px]">
          <Typography className="!font-bold !text-[44px] !text-[#2a2941]">
            Savollaringiz bormi? Biz javob berishga tayyormiz!
          </Typography>

          <Typography className="font-[450] text-[20px] text-[#6e6d77]">
            Keling, suhbatni boshlaymiz. O'zingiz haqida bir oz aytib bering,
            biz imkon qadar tezroq sizga javob beramiz.
          </Typography>

          <Flex vertical className="gap-[24px]">
            <Flex className="items-center gap-[12px]">
              <Flex className="w-[36px] h-[36px] items-center justify-center rounded-full bg-[#d8f3dc]">
                <PhoneFilled className="text-[#40916C]" />
              </Flex>

              <Flex>
                <Typography.Text className="text-[20px] font-[500]">
                  +998 71 208 10 19
                </Typography.Text>
              </Flex>
            </Flex>

            <Flex className="items-center gap-[12px]">
              <Flex className="w-[36px] h-[36px] items-center justify-center rounded-full bg-[#d8f3dc]">
                <MailFilled className="text-[#40916C]" />
              </Flex>

              <Flex>
                <Typography.Text className="text-[20px] font-[500]">
                  info@edalolatnoma.uz
                </Typography.Text>
              </Flex>
            </Flex>

            <Flex className="items-center gap-[12px]">
              <Flex className="w-[36px] h-[36px] items-center justify-center rounded-full bg-[#d8f3dc]">
                <RefIcon className="text-[#40916C]" />
              </Flex>

              <Flex>
                <Typography.Text className="text-[20px] font-[500]">
                  Uzbekistan, Tashkent
                </Typography.Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex vertical className="h-full items-center gap-6 justify-center">
          <Input
            placeholder="F.I.O"
            value={data?.name}
            onChange={(e) => {
              setData({ ...data, name: e.target.value });
            }}
          />
          <Input
            placeholder="Telefon raqamingiz"
            value={data?.phone}
            onChange={(e) => {
              setData({ ...data, phone: e.target.value });
            }}
          />
          <Input
            placeholder="Email"
            value={data?.email}
            onChange={(e) => {
              setData({ ...data, email: e.target.value });
            }}
          />
          <TextArea
            placeholder="Xabar"
            rows={10}
            value={data?.message}
            onChange={(e) => {
              setData({ ...data, message: e.target.value });
            }}
          />
          <Button
            type="primary"
            className="w-max"
            onClick={() => setOpenModal(true)}
          >
            Yuborish
          </Button>
        </Flex>
      </Flex>

      <Modal
        visible={openModal}
        onCancel={() => {
          setOpenModal(false);
          setData({
            name: "",
            phone: "",
            message: "",
            email: "",
          });
        }}
        footer={null}
      >
        <Result status="success" title="Muvaffaqiyatli yuborildi!" />
      </Modal>
    </Flex>
  );
}
